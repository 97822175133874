import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import ProcessSections from '../../../utils/processSections'

const FullContentStyles = styled.div``

const BriefHistoryStyles = styled.div``

export default function ExpandedHistory({ data }) {
  const sections = data.pageContent.nodes[0]

  return (
    <FullContentStyles className="content-width">
      <div>
        <ProcessSections
          sections={sections}
          SectionStyles={BriefHistoryStyles}
        />
      </div>
    </FullContentStyles>
  )
}

export const query = graphql`
  query historyQuery {
    pageContent: allSanityPageContent(
      filter: { location: { eq: "/history/expanded-history" } }
    ) {
      nodes {
        id
        displayTitle
        title
        content: _rawContent
      }
    }
  }
`
